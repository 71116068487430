import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <div className="wirewheel-footer">
      <Container>
        <Row>
          <Col className="text-center text-white p-4">
            <p className="m-0">
              © <span>{new Date().getFullYear()}</span>{" "}
              <a href="/" className="footer-link">
                Impartner Vendor Assessment Center
              </a>{" "}
              All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
